import React from 'react';
import { AppBar, Toolbar, Typography, Container, Grid, Box, Button, Paper, IconButton } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

// Substitua este import pela localização da sua ilustração
import FriendlyCharacterImage from '../images/cta-bg.jpg';

function Index() {
    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="menu">
                        <MailOutlineIcon />
                    </IconButton>
                    <Typography variant="h6" style={{ flexGrow: 1 }}>
                        RodMail
                    </Typography>
                </Toolbar>
            </AppBar>

            <Container maxWidth="lg" style={{ marginTop: '2rem' }}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h4" gutterBottom>
                            Email Temporário com RodMail
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Obtenha um endereço de email temporário de forma rápida e segura.
                            Proteja sua identidade e mantenha sua caixa de entrada livre de spam.
                        </Typography>
                        <Button variant="contained" color="primary" style={{ marginTop: '1rem' }}>
                            Comece Agora
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <img src={FriendlyCharacterImage} alt="Friendly Character" style={{ width: '100%', height: 'auto' }} />
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default Index;
