import logo from './logo.svg';
import './App.css';
import ListMails from './pages/ListMails';
import ListMailsV2 from './pages/ListMailsV2';
import Login from './pages/Login';
import Index from './pages/Index';
import ReadMail from './pages/ReadMail';
import {
    BrowserRouter as Router,
    Routes,
    Route
} from 'react-router-dom';

function App() {
  return (
      <Router>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/login" element={<Login />} />
          <Route path="/list-mails" element={<ListMails />} />
          <Route path="/list-mails/v2" element={<ListMailsV2 />} />
            <Route path="/email/:emailId" element={<ReadMail />} />
          {/* Outras rotas aqui */}
        </Routes>
      </Router>
  );
}

export default App;
