import React, {useState, useEffect} from 'react';
import axios from 'axios';
import '../css/ListMails.css';
import {
    AppBar, Toolbar, Typography, InputBase, Tab, Tabs, Box, IconButton, Avatar, Paper, Grid,
    List, ListItem, Divider, Pagination, Skeleton, Drawer
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {lightBlue, blueGrey, deepOrange} from '@mui/material/colors';
import Footer from './Component/Footer';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";
import DeleteIcon from "@mui/icons-material/Delete";

//import { Link } from 'react-router-dom';


function ListMails() {
    const [emails, setEmails] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [activeTab, setActiveTab] = useState(0);
    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
    const [searchTerm, setSearchTerm] = useState('');
    const [lastEmailID, setLastEmailID] = useState(0);
    const [checkForNewEmails, setCheckForNewEmails] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedEmail, setSelectedEmail] = useState(null);


    const buscarEmails = (checkNewEmails = false, force_refresh = false) => {
        const tabMapping = {0: 'all', 1: 'important', 2: 'marketing'};
        const tab = activeTab;

        if (!(checkNewEmails)) {
            setIsLoading(true);
        }

        let apiUrl = `http://localhost/RodMail/apiv2/email?page=${currentPage}&category=${tabMapping[tab]}&search=${searchTerm}`;
        if (checkNewEmails && lastEmailID > 0 && currentPage === 1) {
            apiUrl += `&lastID=${lastEmailID}`;
        }

        axios.get(apiUrl)
            .then(response => {
                if (response.data.emails && response.data.emails.length > 0) {
                    if (checkNewEmails) {
                        if (currentPage === 1 && (!force_refresh)) {
                            setEmails(prevEmails => [...response.data.emails, ...prevEmails]);
                        } else {
                            setEmails(response.data.emails);
                        }
                    } else {
                        setEmails(response.data.emails); // Substitui a lista de e-mails ao mudar de página
                        setTotalPages(response.data.pager.totalPages);
                    }

                    // Atualizar lastEmailID apenas na primeira página ou quando não estiver verificando novos e-mails
                    if (currentPage === 1 || !checkNewEmails) {
                        const lastID = response.data.emails[0].id;
                        setLastEmailID(lastID);
                    }
                }
                setIsLoading(false);
            })
            .catch(error => {
                console.error("Error fetching emails:", error);
                setIsLoading(false);
            });
    };


    useEffect(() => {
        buscarEmails();

        const interval = setInterval(() => {
            setCheckForNewEmails(true);
        }, 10000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (checkForNewEmails) {
            buscarEmails(true);
            setCheckForNewEmails(false);
        }
    }, [checkForNewEmails]);

    useEffect(() => {
        // Chama buscarEmails sempre que a currentPage mudar
        buscarEmails();
        setLastEmailID(0); // Resetar lastEmailID para buscar e-mails da nova página
    }, [currentPage]); // Observa mudanças em currentPage


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const now = new Date();
        if (date.toDateString() === now.toDateString()) {
            return date.toLocaleTimeString();
        }
        return date.toLocaleDateString();
    };

    const extractTextFromHTML = (htmlString, size) => {
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = htmlString;
        tempDiv.querySelectorAll('script, style').forEach(element => element.remove());
        let text = tempDiv.textContent || tempDiv.innerText || "";
        text = text.replace(/\s+/g, ' ').trim();
        return text.substr(0, size);
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
        setCurrentPage(1);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSearchSubmit = (event) => {
        event.preventDefault();
        setLastEmailID(0);
        setCurrentPage(0);

         buscarEmails();
    };

    const updateViewportHeight = () => {
        setViewportHeight(window.innerHeight);
    };

    const updateListRead = (emailId, value) => {
        // Atualiza o estado para marcar o email como lido
        setEmails(emails.map(email => {
            if (email.id === emailId) {
                return {...email, lido: value};
            }
            return email;
        }));
    };

    const removerEmail = (emailId) => {
        // Atualiza o estado removendo o e-mail com o emailId fornecido
        const emailsAtualizados = emails.filter(email => email.id !== emailId);
        setEmails(emailsAtualizados);

        // Se necessário, aqui você pode também chamar uma API para remover o e-mail do servidor
    };


    const handleEmailClick = (email) => {
        setSelectedEmail(email);
        setDrawerOpen(true);
        updateAsRead(email.id, 'S')
        updateListRead(email.id, 'S')
    };

    const updateAsRead = (id, value) => {
        axios.get(`http://localhost/RodMail/apiv2/email/${id}/updateRead/${value}`) // Substitua pela URL da sua API
            .then(response => {
                console.log("Email marcado como lido");
            })
            .catch(error => {
                console.error("Erro ao marcar email como lido:", error);
            });
    };

    const softDeleteEmail = (id) => {
        axios.get(`http://localhost/RodMail/apiv2/email/${id}/delete`) // Substitua pela URL da sua API
            .then(response => {
                console.log("Email excluido como lido");
            })
            .catch(error => {
                console.error("Erro ao marcar email como lido:", error);
            });
    };

    const paperMaxHeight = `${viewportHeight * 0.73}px`;

    const renderSkeleton = () => {
        return Array.from(new Array(10)).map((_, index) => (
            <React.Fragment key={index}>
                <ListItem sx={{
                    backgroundColor: 'white',
                    boxShadow: 1,
                    mb: 2,
                    p: 2,
                    borderRadius: '4px',
                    '&:last-child': {mb: 0}
                }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={1} sx={{display: 'flex', alignItems: 'center'}}>
                            <Skeleton variant="circular" width={40} height={40} sx={{marginRight: 1}}/>
                            <Skeleton variant="rectangular" width="1px" height="100%"
                                      sx={{bgcolor: 'grey.300', boxShadow: '2px 0px 5px rgba(0, 0, 0, 0.1)'}}/>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Skeleton variant="text" sx={{fontSize: '1rem', marginBottom: '5px'}}/>
                            <Skeleton variant="text" width="80%" sx={{fontSize: '0.9rem'}}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Skeleton variant="text" sx={{fontSize: '1rem', marginBottom: '5px'}}/>
                            <Skeleton variant="text" width="80%" sx={{fontSize: '0.9rem'}}/>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Skeleton variant="text" width="60%" sx={{fontSize: '0.9rem'}}/>
                        </Grid>
                    </Grid>
                </ListItem>
                {index < 4 && <Divider/>}
            </React.Fragment>
        ));
    };

    return (
        <Box p={3}>
            <AppBar position="fixed" sx={{width: '100vw', boxSizing: 'border-box', m: 0}}>
                <Toolbar sx={{justifyContent: 'space-between'}}>
                    <Typography variant="h6" noWrap sx={{flexGrow: 0, display: {xs: 'none', sm: 'block'}}}>
                        RodMail
                    </Typography>
                    <Box sx={{flexGrow: 1, display: 'flex', justifyContent: 'center'}}>
                        <Box component="form"
                             sx={{position: 'relative', display: 'flex', alignItems: 'center', width: '80%'}}
                             onSubmit={handleSearchSubmit}>
                            <InputBase
                                sx={{
                                    ml: 1,
                                    flex: 1,
                                    color: 'black',
                                    background: 'white',
                                    padding: '0 10px',
                                    borderRadius: '4px',
                                    width: '100%'
                                }}
                                value={searchTerm}
                                onChange={handleSearchChange}
                                placeholder="Buscar emails..."
                            />
                            <IconButton type="submit" sx={{p: '10px', position: 'absolute', right: 0, color: 'grey'}}
                                        aria-label="search">
                                <SearchIcon/>
                            </IconButton>
                        </Box>
                    </Box>
                    <Avatar sx={{bgcolor: 'secondary.main'}}>R</Avatar>
                </Toolbar>
            </AppBar>
            <Typography variant="h4" gutterBottom sx={{color: lightBlue[600]}}>
                Caixa de Entrada
            </Typography>
            <Tabs value={activeTab} onChange={handleTabChange} sx={{marginBottom: 2}}>
                <Tab label="Todos"/>
                <Tab label="Importante"/>
                <Tab label="Marketing"/>
            </Tabs>
            {
                (isLoading ?
                        (
                            <Paper elevation={3}
                                   sx={{maxHeight: paperMaxHeight, overflow: 'auto', backgroundColor: blueGrey[50]}}>
                                <List sx={{padding: 0}}>
                                    {renderSkeleton()}
                                </List>
                            </Paper>
                        )
                        :
                        (
                            <Paper elevation={3}
                                   sx={{maxHeight: paperMaxHeight, overflow: 'auto', backgroundColor: blueGrey[50]}}>
                                <List sx={{padding: 0}}>
                                    {emails.map((email, index) => (
                                        <React.Fragment key={index}>
                                            <ListItem
                                                onClick={() => handleEmailClick(email)}
                                                sx={{
                                                    backgroundColor: (email.lido === 'S' ? '#efefef' : '#fff'),
                                                    boxShadow: 1,
                                                    mb: 1,
                                                    p: 1,
                                                    borderRadius: '4px',
                                                    '&:last-child': {mb: 0},
                                                }}
                                            >
                                                <Grid container spacing={2}>
                                                    {/* Componente de Avatar */}
                                                    <Grid item xs={12} sm={1}
                                                          sx={{display: 'flex', alignItems: 'center'}}>
                                                        <Avatar sx={{
                                                            bgcolor: lightBlue[500],
                                                            width: 40,
                                                            height: 40,
                                                            fontSize: '1rem',
                                                            marginRight: 1
                                                        }}>
                                                            {email.recebido_por_nome.charAt(0).toUpperCase()}
                                                            {email.recebido_por_nome.charAt(1).toUpperCase()}
                                                        </Avatar>
                                                    </Grid>

                                                    {/* Componente de Informações do Email */}
                                                    <Grid item xs={12} sm={11}>

                                                        <Grid container spacing={2}>
                                                            {/* Conteúdo da esquerda */}
                                                            <Grid item xs={10} sm={2}
                                                                  sx={{borderRight: '2px solid #e0e0e0'}}>
                                                                <Typography variant="subtitle1" gutterBottom noWrap
                                                                            sx={{fontSize: {xs: '0.9rem', sm: '1rem'}}}>
                                                                    {email.id} - {email.recebido_por_nome}
                                                                </Typography>
                                                                <Typography variant="body2" color="textSecondary" noWrap
                                                                            sx={{
                                                                                fontSize: {
                                                                                    xs: '0.8rem',
                                                                                    sm: '0.9rem'
                                                                                }
                                                                            }}>
                                                                    {email.recebido_por}
                                                                </Typography>
                                                            </Grid>

                                                            {/* Conteúdo do centro */}
                                                            <Grid item xs={12} sm={7}>
                                                                <Typography variant="body1" gutterBottom noWrap
                                                                            sx={{fontSize: {xs: '0.9rem', sm: '1rem'}}}>
                                                                    {email.subject}
                                                                </Typography>
                                                                <Typography variant="body2" color="textSecondary" noWrap
                                                                            sx={{
                                                                                fontSize: {
                                                                                    xs: '0.8rem',
                                                                                    sm: '0.9rem'
                                                                                }
                                                                            }}>
                                                                    {extractTextFromHTML(email.html).slice(0, 100)}...
                                                                </Typography>
                                                            </Grid>

                                                            {/* Conteúdo da direita */}
                                                            <Grid item xs={12} sm={1}>
                                                                <Typography variant="body2" sx={{
                                                                    fontSize: {
                                                                        xs: '0.8rem',
                                                                        sm: '0.9rem'
                                                                    }
                                                                }}>
                                                                    {formatDate(email.data_hora_recebimento)}
                                                                </Typography>
                                                                <Typography variant="body1" gutterBottom noWrap
                                                                            sx={{fontSize: {xs: '0.9rem', sm: '1rem'}}}>
                                                                    {email.lido === 'S' ? 'Lido' : 'Não Lido'}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>

                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                            {index < emails.length - 1 && <Divider/>}
                                        </React.Fragment>
                                    ))}
                                </List>
                            </Paper>
                        )
                )
            }
            <Box display="flex" justifyContent="right" mt={2} mb={5}> {/* Adiciona uma margem inferior */}
                <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} color="primary"/>
            </Box>

            <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
            >
                <Box p={2} width={"45vw"} maxWidth="100vw">
                    {selectedEmail && (

                        <Box sx={{
                            position: 'sticky',
                            top: 64, // Altura do AppBar, ajuste conforme necessário
                            backgroundColor: '#fff',
                            zIndex: 1100,
                        }}>
                            <Box sx={{display: 'flex', alignItems: 'center', mb: 2}}>
                                <IconButton onClick={() => setDrawerOpen(false)} sx={{mr: 2}}>
                                    <ArrowBackIcon/>
                                </IconButton>
                                <Typography variant="h5" gutterBottom>
                                    {selectedEmail.subject}
                                </Typography>
                            </Box>
                            <Divider/>
                            <Box sx={{display: 'flex', alignItems: 'center', mt: 2}}>
                                <Avatar sx={{bgcolor: deepOrange[500], mr: 2}}>
                                    {selectedEmail.recebido_por_nome.charAt(0)}
                                </Avatar>
                                <Typography variant="body1" sx={{flexGrow: 1}}>
                                    {selectedEmail.recebido_por}, Recebido
                                    em {new Date(selectedEmail.data_hora_recebimento).toLocaleTimeString()}
                                </Typography>
                                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', mr: 2}}>
                                    <IconButton onClick={() => {
                                        updateListRead(selectedEmail.id, 'N')
                                        updateAsRead(selectedEmail.id, 'N');
                                    }}>
                                        <MarkEmailUnreadIcon/>
                                    </IconButton>
                                    <Typography variant="body2">Não Lido</Typography>
                                </Box>
                                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <IconButton onClick={() => {
                                        softDeleteEmail(selectedEmail.id);
                                        removerEmail(selectedEmail.id);
                                        setDrawerOpen(false);
                                    }}>
                                        <DeleteIcon/>
                                    </IconButton>
                                    <Typography variant="body2">Excluir</Typography>
                                </Box>
                                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <IconButton onClick={() => {
                                    }}>
                                    </IconButton>
                                    <Typography variant="body2"></Typography>
                                </Box>
                            </Box>

                            <Paper elevation={3} sx={{p: 2, mt: 2}}>
                                <div dangerouslySetInnerHTML={{__html: selectedEmail.html}}/>
                            </Paper>


                        </Box>


                    )}
                </Box>
            </Drawer>
            <Footer/>
        </Box>
    );
}

export default ListMails;
