import React from 'react';
import {Typography} from "@mui/material";

const Footer = () => {
    return (
        <footer style={footerStyle}>
            <Typography variant="body2" align="center">
                © 2024 Rodrigo's Mail Service
            </Typography>
        </footer>
    );
};

const footerStyle = {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    backgroundColor: '#1376D2',
    color: '#fff',
    textAlign: 'center',
    padding: '10px 0',
};

export default Footer;
