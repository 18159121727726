import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {useParams, useNavigate} from 'react-router-dom';
import {
    AppBar, Toolbar, Typography, Box, Avatar, Paper, Skeleton, Divider, IconButton
} from '@mui/material';
import {lightBlue, deepOrange} from '@mui/material/colors';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Footer from './Component/Footer';
import DeleteIcon from '@mui/icons-material/Delete';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';


function ReadMail() {
    let {emailId} = useParams();
    const [email, setEmail] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Carregando email...';
    }, []);

    useEffect(() => {
        setIsLoading(true);

        axios.get(`http://localhost/RodMail/api/email?emailID=${emailId}`) // Substitua pela URL da sua API
            .then(response => {

                let email_dados = response.data.emails[0];
                let subject = email_dados.subject;
                let recebido_por_nome = email_dados.recebido_por_nome;

                document.title = `${recebido_por_nome} - ${subject}`;
                updateAsRead(emailId, 'S');

                setEmail(response.data.emails[0]); // Supondo que a resposta tenha o formato { data: emailData }
                setIsLoading(false);
            })
            .catch(error => {
                console.error("Error fetching email:", error);
                setIsLoading(false);
            });
    }, [emailId]);


    const updateAsRead = (id, value) => {
        axios.get(`http://localhost/RodMail/api/email/${id}/updateRead/${value}`) // Substitua pela URL da sua API
            .then(response => {
                console.log("Email marcado como lido");
            })
            .catch(error => {
                console.error("Erro ao marcar email como lido:", error);
            });
    };


    const createMarkup = (html) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');

        const links = doc.querySelectorAll('a');
        links.forEach(link => {
            link.setAttribute('target', '_blank');
            link.setAttribute('rel', 'noopener noreferrer'); // Prevenir vulnerabilidades de segurança
        });

        return {__html: doc.body.innerHTML};
    };

    const SekeletonLoading = () => {
        return (
            <Box p={3}>
                <AppBar position="fixed" sx={{width: '100vw', boxSizing: 'border-box', m: 0}}>
                    <Toolbar sx={{justifyContent: 'space-between'}}>
                        <Skeleton variant="text" width={100} height={32}/>
                        <Skeleton variant="circular" width={40} height={40}/>
                    </Toolbar>
                </AppBar>

                <Box sx={{marginTop: 8, padding: 2}}>
                    <Skeleton variant="text" height={80}/>
                    <Skeleton variant="text" height={40}/>
                    <Skeleton variant="text" height={20}/>
                    <Skeleton variant="rectangular" height={500}/>
                </Box>

                <Footer/>
            </Box>
        );
    }

    const handleBack = () => {
        navigate(-1); // Volta para a página anterior
    };

    if (isLoading) {
        return (
            <SekeletonLoading/>
        )
    }

    if (!email) {
        return <div>Email não encontrado.</div>;
    }

    return (
        <Box sx={{pt: 8}}> {/* Ajuste aqui para a altura do AppBar */}
            {/* AppBar */}
            <AppBar position="fixed" sx={{width: '100vw', boxSizing: 'border-box', m: 0}}>
                <Toolbar sx={{justifyContent: 'space-between'}}>
                    <Typography variant="h6" noWrap sx={{flexGrow: 0, display: {xs: 'none', sm: 'block'}}}>
                        RodMail
                    </Typography>
                    <Avatar sx={{bgcolor: 'secondary.main'}}>R</Avatar>
                </Toolbar>
            </AppBar>

            {/* Conteúdo fixo abaixo do AppBar */}
            <Box sx={{
                position: 'sticky',
                top: 64, // Altura do AppBar, ajuste conforme necessário
                backgroundColor: '#fff',
                zIndex: 1100,
            }}>
                <Box sx={{display: 'flex', alignItems: 'center', mb: 2}}>
                    <IconButton onClick={handleBack} sx={{mr: 2}}>
                        <ArrowBackIcon/>
                    </IconButton>
                    <Typography variant="h5" gutterBottom>
                        {email.subject}
                    </Typography>
                </Box>
                <Divider/>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                    <Avatar sx={{ bgcolor: deepOrange[500], mr: 2 }}>
                        {email.recebido_por_nome.charAt(0)}
                    </Avatar>
                    <Typography variant="body1" sx={{ flexGrow: 1 }}>
                        {email.recebido_por}, Recebido em {new Date(email.data_hora_recebimento).toLocaleTimeString()}
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mr: 2 }}>
                        <IconButton onClick={() => updateAsRead(emailId, 'N')}>
                            <MarkEmailUnreadIcon />
                        </IconButton>
                        <Typography variant="body2">Não Lido</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <IconButton onClick={() => {}}>
                            <DeleteIcon />
                        </IconButton>
                        <Typography variant="body2">Excluir</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <IconButton onClick={() => {}}>
                        </IconButton>
                        <Typography variant="body2"></Typography>
                    </Box>
                </Box>



            </Box>

            {/* Conteúdo do Email */}
            <Paper elevation={3} sx={{p: 2, mt: 2}}>
                <div dangerouslySetInnerHTML={{__html: email.html}}/>
            </Paper>

            {/* Footer */}
            <Footer/>
        </Box>


    );
}

export default ReadMail;
