import React, {useState, useEffect} from 'react';
import axios from 'axios';

import {
    AppBar, Toolbar, Typography, InputBase, Tab, Tabs, Box, IconButton, Avatar, Paper, Grid,
    List, ListItem, Divider, Pagination, Skeleton, Drawer, useMediaQuery, Menu, MenuItem
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {lightBlue, blueGrey, deepOrange} from '@mui/material/colors';
import Footer from './Component/Footer';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from '@mui/material/TextField'; // Importação do TextField
import Button from '@mui/material/Button'; // Importação do Button
import CloseIcon from '@mui/icons-material/Close'; // Importação do CloseIcon



function ListMails() {
    const [emails, setEmails] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [activeTab, setActiveTab] = useState(0);
    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
    const [searchTerm, setSearchTerm] = useState('');
    const [lastEmailID, setLastEmailID] = useState(0);
    const [checkForNewEmails, setCheckForNewEmails] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedEmail, setSelectedEmail] = useState(null);
    const [endPoint, setEndPoint] = useState('https://api.rodmail.me/')
    //const [endPoint, setEndPoint] = useState('http://192.168.237.64/RodMail/php/')
    const isMobile = useMediaQuery('(max-width:600px)'); // 600px é um breakpoint comum para dispositivos móveis


    const buscarEmails = (checkNewEmails = false, force_refresh = false) => {
        const tabMapping = {0: 'all', 1: 'important', 2: 'marketing'};
        const tab = activeTab;

        if (!(checkNewEmails)) {
            setIsLoading(true);
        }

        let apiUrl = endPoint + `apiv2/email?page=${currentPage}&category=${tabMapping[tab]}&search=${searchTerm}`;
        if (checkNewEmails && lastEmailID > 0 && currentPage === 1) {
            apiUrl += `&lastID=${lastEmailID}`;
        }

        let JWTToken = localStorage.getItem(`JWT_TOKEN`);

        axios.get(apiUrl, {
            headers: {
                'Authorization': `Bearer ${JWTToken}`
            }
        }).then(response => {

            if(response.data.email_list){
                const email_list = response.data.email_list;
                setEmailList(email_list)
            }else{
                setEmailList([]);
            }

            if (response.data.emails && response.data.emails.length > 0) {
                if (checkNewEmails) {
                    if (currentPage === 1 && (!force_refresh)) {
                        setEmails(prevEmails => [...response.data.emails, ...prevEmails]);
                    } else {
                        setEmails(response.data.emails);
                    }
                } else {
                    setEmails(response.data.emails); // Substitui a lista de e-mails ao mudar de página
                    setTotalPages(response.data.pager.totalPages);
                }

                // Atualizar lastEmailID apenas na primeira página ou quando não estiver verificando novos e-mails
                if (currentPage === 1 || !checkNewEmails) {
                    const lastID = response.data.emails[0].id;
                    setLastEmailID(lastID);
                }
            }else if(!checkNewEmails){
                setEmails([]);

            }

            setIsLoading(false);
        }).catch(error => {

            if (error.response) {

                const error_response = error.response;
                const data_error = error_response.data;

                if (data_error && data_error.error && data_error.error_msg) {
                    const error_msg = data_error.error_msg;
                    const error_deslog = data_error.deslog;

                    localStorage.removeItem('JWT_TOKEN');

                    window.location.href = `/login`;
                }

            }

            setIsLoading(false);
        });


    };


    useEffect(() => {

        let jwt = localStorage.getItem(`JWT_TOKEN`);

        if (!jwt) {
            window.location.href = '/login';
            return;
        }

        buscarEmails();

        const interval = setInterval(() => {
            setCheckForNewEmails(true);
        }, 10000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (checkForNewEmails) {
            buscarEmails(true);
            setCheckForNewEmails(false);
        }
    }, [checkForNewEmails]);

    useEffect(() => {
        buscarEmails(false, true);
        setLastEmailID(0);
    }, [currentPage, activeTab]);


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const now = new Date();
        if (date.toDateString() === now.toDateString()) {
            return date.toLocaleTimeString();
        }
        return date.toLocaleDateString();
    };

    const extractTextFromHTML = (htmlString, size) => {
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = htmlString;
        tempDiv.querySelectorAll('script, style').forEach(element => element.remove());
        let text = tempDiv.textContent || tempDiv.innerText || "";
        text = text.replace(/\s+/g, ' ').trim();
        return text.substr(0, size);
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleTabChange = (event, newValue) => {
        setCurrentPage(1);
        setActiveTab(newValue);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);

    };

    const handleSearchSubmit = (event) => {
        event.preventDefault();

        setLastEmailID(0);
        setCurrentPage(0);

        if (!searchTerm) {
            buscarEmails();
        }
    };

    const updateViewportHeight = () => {
        setViewportHeight(window.innerHeight);
    };

    const updateListRead = (emailId, value) => {
        setEmails(emails.map(email => {
            if (email.id === emailId) {
                return {...email, lido: value};
            }
            return email;
        }));
    };

    const removerEmail = (emailId) => {
        const emailsAtualizados = emails.filter(email => email.id !== emailId);
        setEmails(emailsAtualizados);
    };

    const handleEmailClick = (email) => {
        updateAsRead(email.id, 'S')
        updateListRead(email.id, 'S')
        setSelectedEmail(email);
        setDrawerOpen(true);
    };

    const updateAsRead = (id, value) => {
        let JWTToken = localStorage.getItem(`JWT_TOKEN`);
        axios.get(endPoint + `apiv2/email/${id}/updateRead/${value}`, {
            headers: {
                'Authorization': `Bearer ${JWTToken}`
            }
        }).then(response => {
            console.log("Email marcado como lido");
        }).catch(error => {
            console.error("Erro ao marcar email como lido:", error);
        });
    };

    const softDeleteEmail = (id) => {
        let JWTToken = localStorage.getItem(`JWT_TOKEN`);
        axios.get(endPoint + `apiv2/email/${id}/delete`, {
            headers: {
                'Authorization': `Bearer ${JWTToken}`
            }
        }).then(response => {
            console.log("Email excluido como lido");
        }).catch(error => {
            console.error("Erro ao marcar email como lido:", error);
        });
    };

    const paperMaxHeight = `${viewportHeight * 0.73}px`;

    const renderSkeleton = () => {
        return (
            <Grid container spacing={2}>
                {/* Esqueleto para a caixa de adicionar emails - 20% da largura */}
                <Grid item xs={12} sm={3}>
                    <Box sx={{ backgroundColor: 'white', p: 2, borderRadius: '4px', boxShadow: 1, mb: 2 }}>
                        <Skeleton variant="rectangular" height={56} sx={{ borderRadius: '4px' }} />
                    </Box>
                    <Box sx={{ backgroundColor: 'white', p: 2, borderRadius: '4px', boxShadow: 1, overflowY: 'auto' }}>
                        {Array.from(new Array(20)).map((_, index) => (
                            <Box key={index} sx={{ mb: 3, display: 'flex', alignItems: 'center' }}>
                                <Skeleton variant="text" width="80%" sx={{ flexGrow: 1 }} />
                                <Skeleton variant="circular" width={20} height={20} />
                            </Box>
                        ))}
                    </Box>
                </Grid>

                {/* Esqueleto para a listagem de emails - 80% da largura */}
                <Grid item xs={12} sm={9}>
                    {Array.from(new Array(10)).map((_, index) => (
                        <Box key={index} sx={{ backgroundColor: 'white', p: 2, borderRadius: '4px', boxShadow: 1, mb: 2 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={1}>
                                    <Skeleton variant="circular" width={40} height={40} />
                                </Grid>
                                <Grid item xs={12} sm={11}>
                                    <Skeleton variant="text" width="100%" />
                                    <Skeleton variant="text" width="80%" />
                                </Grid>
                            </Grid>
                        </Box>
                    ))}
                </Grid>
            </Grid>
        );
    };



    const updateAsImportant = (id, value) => {
        let JWTToken = localStorage.getItem(`JWT_TOKEN`);
        axios.get(endPoint + `apiv2/email/${id}/updateImportant/${value}`, {
            headers: {
                'Authorization': `Bearer ${JWTToken}`
            }
        }).then(response => {
            console.log("Email marcado como lido");
        }).catch(error => {
            console.error("Erro ao marcar email como lido:", error);
        });
    };

    const drawerStyles = {
        padding: 2,
        width: isMobile ? '87vw' : '45vw',
        maxWidth: '100vw',
        height: isMobile ? '100vh' : 'auto', // Ocupa toda a altura da tela em dispositivos móveis
    };

    let letraAvatar = 'SN';
    let nomeUsuario = 'Sem Nome';

    try {
        const jwtAvatar = localStorage.getItem(`JWT_TOKEN`);
        const jwtAvatarExplode = jwtAvatar.split(`.`);
        const jwtB64 = atob(jwtAvatarExplode[1]);
        const jwtJSON = JSON.parse(jwtB64);
        letraAvatar = jwtJSON.username.charAt(0).toUpperCase()
        nomeUsuario = jwtJSON.username.toUpperCase();
    } catch (e) {
        letraAvatar = 'ER';
    }

    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        localStorage.removeItem('JWT_TOKEN');
        window.location.href = '/login';
        handleClose();
    };

    const handleImportant = () => {
        const importantStatus = (selectedEmail.important == 'S' ? 'N' : 'S');
        updateAsImportant(selectedEmail.id, importantStatus);

        // Atualize o e-mail selecionado
        setSelectedEmail(prevEmail => {
            if (prevEmail && prevEmail.id === selectedEmail.id) {
                return {...prevEmail, important: importantStatus};
            }
            return prevEmail;
        });

        // Atualize a lista de e-mails
        setEmails(prevEmails => prevEmails.map(email => {
            if (email.id === selectedEmail.id) {
                return {...email, important: importantStatus};
            }
            return email;
        }));
    }

    const [emailList, setEmailList] = useState([]);
    const [inputEmail, setInputEmail] = useState('');

    const addEmailClick = (email) => {
        requestCreateEmailList(email);
    }

    const handleAddEmail = () => {
        if (inputEmail && !emailList.includes(inputEmail)) {
            requestCreateEmailList(inputEmail);
        }
    };

    const requestCreateEmailList = (emailValue) => {
        let JWTToken = localStorage.getItem('JWT_TOKEN');
        axios.post(endPoint + 'apiv2/email-list/create', {
            email: emailValue
        }, {
            headers: {
                'Authorization': `Bearer ${JWTToken}`
            }
        }).then(response => {
            console.log("Email adicionado com sucesso", response);
            buscarEmails()
            setInputEmail('');
        }).catch(error => {
            console.error("Erro ao adicionar email:", error);
            setEmailList([...emailList, emailValue]);
            setInputEmail('');
        });
    }

    const handleRemoveEmail = (emailToRemove) => {
        let JWTToken = localStorage.getItem('JWT_TOKEN');
        axios.delete(endPoint + 'apiv2/email-list/delete/' + emailToRemove, {
            headers: {
                'Authorization': `Bearer ${JWTToken}`
            },
            data: { email: inputEmail } // Dados enviados no corpo da requisição DELETE
        }).then(response => {
            console.log("Email excluído com sucesso", response);
            buscarEmails();
        }).catch(error => {
            console.error("Erro ao excluir email:", error);
        });
    };



    return (
        <Box p={3}>
            <AppBar position="fixed" sx={{width: '100vw', boxSizing: 'border-box', m: 0}}>
                <Toolbar sx={{justifyContent: 'space-between'}}>
                    <Typography variant="h6" noWrap sx={{flexGrow: 0, display: {xs: 'none', sm: 'block'}}}>
                        RodMail
                    </Typography>
                    <Box sx={{flexGrow: 1, display: 'flex', justifyContent: 'center'}}>
                        <Box component="form"
                             sx={{position: 'relative', display: 'flex', alignItems: 'center', width: '80%'}}
                             onSubmit={handleSearchSubmit}>
                            <InputBase
                                sx={{
                                    ml: 1,
                                    flex: 1,
                                    color: 'black',
                                    background: 'white',
                                    padding: '0 10px',
                                    borderRadius: '4px',
                                    width: '100%'
                                }}
                                value={searchTerm}
                                onChange={handleSearchChange}
                                placeholder="Buscar emails..."
                            />
                            <IconButton type="submit" sx={{p: '10px', position: 'absolute', right: 0, color: 'grey'}}
                                        aria-label="search">
                                <SearchIcon/>
                            </IconButton>
                        </Box>
                    </Box>
                    <Avatar
                        sx={{bgcolor: 'secondary.main'}}
                        onClick={handleMenu}
                    >
                        {letraAvatar}
                    </Avatar>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem disabled>{nomeUsuario}</MenuItem>
                        <Divider/>
                        <MenuItem component="a" onClick={handleLogout}>Sair</MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
            <Typography variant="h4" gutterBottom sx={{color: lightBlue[600]}}>
                Caixa de Entrada
            </Typography>
            <Tabs value={activeTab} onChange={handleTabChange} sx={{marginBottom: 2}}>
                <Tab label="Todos"/>
                <Tab label="Importante"/>
                <Tab label="Marketing"/>
            </Tabs>
            {
                (isLoading ?
                        (
                            <Paper elevation={3}
                                   sx={{maxHeight: paperMaxHeight, overflow: 'auto', backgroundColor: blueGrey[50]}}>
                                <List sx={{padding: 0}}>
                                    {renderSkeleton()}
                                </List>
                            </Paper>
                        )
                        :
                        (

                            <Box p={3} sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mb: 1,
                                p: 1,
                                border: 1,
                                borderColor: 'grey.200',
                                borderRadius: 1,
                                backgroundColor: 'grey.10',
                                boxShadow: 1,
                            }}>
                                {/* AppBar e outros componentes... */}

                                <Grid container spacing={2}>
                                    {/* Caixa para adicionar emails - 20% da largura */}
                                    <Grid item xs={12} sm={3}>
                                        <Box>
                                            <TextField
                                                label="Adicionar Email"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                value={inputEmail}
                                                onChange={e => setInputEmail(e.target.value)}
                                                sx={{ mb: 2 }}
                                            />
                                            <Button variant="contained" color="primary" fullWidth onClick={handleAddEmail}>
                                                Adicionar
                                            </Button>
                                        </Box>
                                        {/* Listagem de emails */}
                                        <Box sx={{
                                            my: 2,
                                            maxHeight: 300,
                                            overflowY: 'auto',
                                            border: 1,
                                            borderColor: 'grey.300',
                                            borderRadius: 2,
                                            p: 2,
                                            boxShadow: 1,
                                        }}>

                                            {
                                                emailList.length > 0 ? (
                                                    <div>
                                                        {emailList.map((email, index) => (
                                                            <Box key={index} sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                mb: 1,
                                                                p: 1,
                                                                border: 1,
                                                                borderColor: 'grey.200',
                                                                borderRadius: 1,
                                                                backgroundColor: 'grey.50',
                                                                boxShadow: 1,
                                                            }}>
                                                                <Typography sx={{ flexGrow: 1 }}>{email.email}</Typography>
                                                                <IconButton size="small" onClick={() => handleRemoveEmail(email.id)}>
                                                                    <CloseIcon />
                                                                </IconButton>
                                                            </Box>
                                                        ))}
                                                    </div>
                                                ) : (
                                                    <Box key={0} sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        mb: 1,
                                                        p: 1,
                                                        border: 1,
                                                        borderColor: 'grey.200',
                                                        backgroundColor: 'grey.50',
                                                        boxShadow: .1,
                                                    }}>
                                                        <Typography sx={{ flexGrow: 1, alignItems: 'center', textAlign: 'center' }}>NENHUM EMAIL ADICIONADO!</Typography>
                                                    </Box>
                                                )
                                            }



                                        </Box>
                                    </Grid>

                                    {/* Listagem de emails - 80% da largura */}
                                    <Grid item xs={12} sm={9}>
                                        <Paper elevation={3}
                                               sx={{maxHeight: paperMaxHeight, overflow: 'auto', backgroundColor: blueGrey[50]}}>
                                            <List sx={{padding: 0}}>
                                                {emails.map((email, index) => (
                                                    <React.Fragment key={index}>
                                                        <ListItem
                                                            onClick={() => handleEmailClick(email)}
                                                            sx={{
                                                                backgroundColor: email.lido === 'S' ? '#efefef' : '#fff',
                                                                boxShadow: 1,
                                                                mb: 1,
                                                                p: 1,
                                                                borderRadius: '4px',
                                                                '&:last-child': {mb: 0},
                                                                display: 'flex',
                                                                flexDirection: {xs: 'column', sm: 'row'}, // Coluna única para móveis, linha para desktop
                                                            }}
                                                        >
                                                            <Grid container spacing={2}>
                                                                {/* Componente de Avatar */}
                                                                <Grid item xs={12} sm={1}
                                                                      sx={{display: 'flex', alignItems: 'center'}}>
                                                                    <Avatar sx={{
                                                                        bgcolor: lightBlue[500],
                                                                        width: 40,
                                                                        height: 40,
                                                                        fontSize: '1rem',
                                                                        marginRight: 1
                                                                    }}>
                                                                        {email.from_name.charAt(0).toUpperCase()}
                                                                        {email.from_name.charAt(1).toUpperCase()}
                                                                    </Avatar>
                                                                </Grid>

                                                                {/* Componente de Informações do Email */}
                                                                <Grid item xs={12} sm={11}>

                                                                    <Grid container spacing={2}>
                                                                        {/* Conteúdo da esquerda */}
                                                                        <Grid item xs={10} sm={2}
                                                                        >
                                                                            <Typography variant="subtitle1" gutterBottom noWrap
                                                                                        sx={{fontSize: {xs: '0.9rem', sm: '1rem'}}}>
                                                                                {email.id} - {email.from_name}
                                                                            </Typography>
                                                                            <a href={`#`} style={{ textDecoration: 'none' }} onClick={(e) => { e.stopPropagation(); addEmailClick(email.to_address); }}>
                                                                                <Typography variant="body2" color="textSecondary" noWrap
                                                                                            sx={{
                                                                                                fontSize: {
                                                                                                    xs: '0.8rem',
                                                                                                    sm: '0.9rem'
                                                                                                },
                                                                                                color: 'rgba(11,33,173,0.83)'
                                                                                            }}>
                                                                                    {email.to_address}
                                                                                </Typography>
                                                                            </a>

                                                                        </Grid>

                                                                        {/* Conteúdo do centro */}
                                                                        <Grid item xs={12} sm={7}>
                                                                            <Typography variant="body1" gutterBottom noWrap
                                                                                        sx={{fontSize: {xs: '0.9rem', sm: '1rem'}}}>
                                                                                {email.subject}
                                                                            </Typography>
                                                                            <Typography variant="body2" color="textSecondary" noWrap
                                                                                        sx={{
                                                                                            fontSize: {
                                                                                                xs: '0.8rem',
                                                                                                sm: '0.9rem'
                                                                                            }
                                                                                        }}>
                                                                                {extractTextFromHTML(email.html_preview).slice(0, 100)}...
                                                                            </Typography>
                                                                        </Grid>

                                                                        {/* Conteúdo da direita */}
                                                                        <Grid item xs={12} sm={1}>
                                                                            <Typography variant="body2" sx={{
                                                                                fontSize: {
                                                                                    xs: '0.8rem',
                                                                                    sm: '0.9rem'
                                                                                }
                                                                            }}>
                                                                                {formatDate(email.created_at)}
                                                                            </Typography>
                                                                            <Typography variant="body1" gutterBottom noWrap
                                                                                        sx={{fontSize: {xs: '0.9rem', sm: '1rem'}}}>
                                                                                {email.lido === 'S' ? 'Lido' : 'Não Lido'}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>

                                                                </Grid>
                                                            </Grid>
                                                        </ListItem>
                                                        {index < emails.length - 1 && <Divider/>}
                                                    </React.Fragment>
                                                ))}
                                            </List>
                                        </Paper>
                                    </Grid>
                                </Grid>

                                {/* Outros componentes... */}
                            </Box>


                        )
                )
            }
            <Box display="flex" justifyContent="right" mt={2} mb={5}> {/* Adiciona uma margem inferior */}
                <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} color="primary"/>
            </Box>

            <Drawer
                anchor={isMobile ? 'bottom' : 'right'}
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
            >
                <Box p={2} width={"45vw"} maxWidth="100vw">
                    {selectedEmail && (

                        <Box sx={drawerStyles}>
                            <Box sx={{display: 'flex', alignItems: 'center', mb: 2}}>
                                <IconButton onClick={() => setDrawerOpen(false)} sx={{mr: 2}}>
                                    <ArrowBackIcon/>
                                </IconButton>
                                <Typography variant="h5" gutterBottom>
                                    {selectedEmail.subject}
                                </Typography>
                            </Box>
                            <Divider/>
                            <Box sx={{display: 'flex', alignItems: 'center', mt: 2}}>
                                <Avatar sx={{bgcolor: deepOrange[500], mr: 2}}>
                                    {selectedEmail.from_name.charAt(0)}
                                </Avatar>
                                <Typography variant="body1" sx={{flexGrow: 1}}>
                                    {selectedEmail.to_address}, Recebido
                                    em {new Date(selectedEmail.date_received).toLocaleTimeString()}
                                </Typography>
                                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', mr: 2}}>
                                    <IconButton onClick={() => {
                                        updateListRead(selectedEmail.id, 'N')
                                        updateAsRead(selectedEmail.id, 'N');
                                    }}>
                                        <MarkEmailUnreadIcon/>
                                    </IconButton>
                                    <Typography variant="body2">Não Lido</Typography>
                                </Box>
                                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', mr: 2}}>
                                    <IconButton onClick={handleImportant}>
                                        {selectedEmail.important == 'S' ? <StarIcon/> : <StarBorderIcon/>}
                                    </IconButton>
                                    <Typography variant="body2">Favorito</Typography>
                                </Box>
                                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <IconButton onClick={() => {
                                        softDeleteEmail(selectedEmail.id);
                                        removerEmail(selectedEmail.id);
                                        setDrawerOpen(false);
                                    }}>
                                        <DeleteIcon/>
                                    </IconButton>
                                    <Typography variant="body2">Excluir</Typography>
                                </Box>
                                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <IconButton onClick={() => {
                                    }}>
                                    </IconButton>
                                    <Typography variant="body2"></Typography>
                                </Box>
                            </Box>

                            <Paper elevation={3} sx={{p: 2, mt: 2}}>
                                <div dangerouslySetInnerHTML={{__html: selectedEmail.html_content}}/>
                            </Paper>


                        </Box>


                    )}
                </Box>
            </Drawer>
            <Footer/>
        </Box>
    );
}

export default ListMails;
