import React, { useState } from 'react';
import { Container, TextField, Button, Typography, makeStyles, Paper } from '@material-ui/core';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        padding: theme.spacing(2),
        position: 'relative',
        zIndex: 2,
    },
    formWrapper: {
        marginTop: theme.spacing(6),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2),
        backgroundColor: 'white', // Cor de fundo do painel
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[5], // Sombra para dar profundidade
        maxWidth: '600px', // Largura máxima para desktop
        width: '100%', // Ajusta-se à largura do container
    },
    form: {
        marginTop: theme.spacing(1),
        width: '100%', // 100% da largura do painel
    },
    inputField: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        width: '100%' // 100% da largura do formulário
    },
    submitButton: {
        marginTop: theme.spacing(2),
        width: '100%'
    },
    background: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage: 'url("https://www.hdwallpapers.in/download/minimalist_mountain_sunset_artistic_minimalism_4k_hd_minimalism-3840x2160.jpg")', // Substitua com sua imagem
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        zIndex: 1,
        transform: 'scale(1.1)', // Amplia a imagem
    },
}));

function Login() {
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleMouseMove = (e) => {
        // ... manipulação do movimento do mouse ...
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        let api_url = 'https://api.rodmail.me/';

        try {
            const response = await axios.get( api_url + 'api/user/login', {
                params: {
                    email: email,
                    password: password
                }
            });
            let data = response.data;

            let token = data.token;

            localStorage.setItem('JWT_TOKEN', token);

            window.location.href = '/list-mails/v2';

        } catch (error) {
            console.error('Erro ao fazer login:', error);
            alert('Dados de acesso incorreto!')
        }

    };


    return (
        <div className={classes.backgroundContainer}>
            <Container className={classes.container}>
                <Paper className={classes.formWrapper}>
                    <Typography variant="h4" component="h1" gutterBottom>Login</Typography>
                    <form className={classes.form} noValidate onSubmit={handleSubmit}>
                        <TextField
                            className={classes.inputField}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                            className={classes.inputField}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submitButton}
                        >
                            Sign In
                        </Button>
                    </form>
                </Paper>
            </Container>
        </div>
    );
}

export default Login;
